import "./App.css";

import logo from "./img/logo.svg";

function App() {
  return (
    <div className="App">
      <span>Ведутся технические работы!</span>
    </div>
  );
}

export default App;
